<script setup>
import { computed } from 'vue'

defineOptions({ name: 'PictureSet', inheritAttrs: true })

const props = defineProps({
  alt: { type: String, default: 'An un-captioned image' },
  class: [String, Array, Object],
  default: { type: String, required: true },
  formats: { type: Array, required: true },
})

const baseUrl = location.protocol + '//' + location.host

const actualSrc = computed(() => {
  if (props.default.startsWith('http')) return props.default

  return `${baseUrl}/${props.default}`
})

const alternates = computed(() => {
  let extParts = props.default.split('.')

  extParts.pop()

  const basePath = extParts.join('.')

  const sources = []

  for (const format of props.formats)
    sources.push(basePath + '.' + format)

  return sources
})
</script>

<template>
  <picture>
    <source
      v-for="altImage in alternates"
      :srcset="altImage"
      :alt="$props.alt"
      :class="$props.class"
    />

    <img
      :src="actualSrc"
      :alt="$props.alt"
      :class="$props.class"
    />
  </picture>
</template>
